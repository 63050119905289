import React from "react";
// Librerías y estilos globales
import { Routes, Route, Navigate } from "react-router-dom";
import GlobalStyle from "./constants/globalStyles";
// Home y Pasos
import InicioPage from "./pages/inicio-page";
import PasosPage from "./pages/pasos-page";
// Referidos
import ReferidosPage from "./pages/referidos-page";
// Ayuda
import BajaPage from "./pages/baja-page";
import ArrepentimientoPage from "./pages/arrepentimiento-page";
import ContactoPage from "./pages/contacto-page";
// Legales y La empresa
import InstitucionalPage from "./pages/institucional-page";
import FormaPartePage from "./pages/formaparte-page";

function App() {
  return (
    <div className="App">
      <Routes>
        {/* ------------------ Home y Pasos ------------------ */}

        {/* Inicio */}
        <Route exact path="/" element={<InicioPage />} />
        {/* Pasos */}
        <Route exact path="/pasos" element={<PasosPage />} />

        {/* ------------------ Red y Referidos ------------------ */}

        {/* Referidos */}
        <Route exact path="/referidos" element={<ReferidosPage />} />

        {/* ------------------ Ayuda ------------------ */}

        {/* Arrepentimiento de compra */}
        <Route
          exact
          path="/arrepentimiento-de-compra"
          element={<ArrepentimientoPage />}
        />
        {/* Baja de servicio */}
        <Route exact path="/baja-de-servicio" element={<BajaPage />} />
        {/* Contacto */}
        <Route exact path="/contacto" element={<ContactoPage />} />

        {/* ------------------ Legales y La empresa ------------------ */}

        {/* Políticas y términos */}
        <Route
          exact
          path="/politicas-y-terminos"
          element={
            <InstitucionalPage
              pagina="politicas-y-terminos"
              tituloSpan="Políticas y términos"
              titulo=" - Gestión y Servicios"
            />
          }
        />
        {/* Sobre Nosotros */}
        <Route
          exact
          path="/sobre-nosotros"
          element={
            <InstitucionalPage
              pagina="sobre-nosotros"
              tituloSpan="Sobre nosotros"
              titulo=" - Gestión y Servicios"
            />
          }
        />
        {/* Formá Parte */}
        <Route exact path="/forma-parte" element={<FormaPartePage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <GlobalStyle></GlobalStyle>
    </div>
  );
}

export default App;
